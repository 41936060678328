<template>
  <div v-if="isLoading">
    <v-skeleton-loader
      type="table-heading"
      class="mb-8"
    />
    <v-row>
      <v-col
        v-for="loadingIdx of 36"
        :key="`loading${loadingIdx}`"
        md="3"
        sm="3"
        cols="4"
        class="my-2 d-flex align-center justify-center"
      >
        <v-skeleton-loader
          type="avatar"
          size="60"
        />
      </v-col>
    </v-row>
  </div>

  <div
    v-else
  >
    <!-- NOTE: 新規作成フォーム start -->
    <div class="pa-3 w-full d-flex align-center justify-end">
      <v-dialog
        v-model="isCreating"
        width="500"
        persistent
      >
        <template #activator="{ on, attrs }">
          <v-btn
            fab
            small
            color="primary"
            :ripple="false"
            v-bind="attrs"
            v-on="on"
            @click="addTargetUser.role = currentRole"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            新規ユーザー
          </v-card-title>
          <v-card-text>
            <div class="w-hull d-flex align-center justify-center">
              <profile-image-input
                :value="addTargetUser.profileImage"
                :change="setImage"
                :display-image="addTargetUser.tempImage"
                class="my-4"
              />
            </div>

            <v-row class="mt-3">
              <v-col
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="addTargetUser.name"
                  label="名前"
                />
              </v-col>

              <v-col
                sm="12"
                md="6"
              >
                <v-text-field
                  :value="{ cast: 'キャスト', waiter: 'ボーイ', alliance: 'アイライアンス' }[currentRole]"
                  disabled
                  label="ロール"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="isSubmitting"
              :disabled="Object.values({ ...isValidAddUser }).includes(false) || isSubmitting"
              :ripple="false"
              @click="create"
            >
              作成
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :ripple="false"
              @click="cancelCreate"
            >
              キャンセル
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- NOTE: 新規作成フォーム end -->

    <v-tabs
      v-if="users.length > 1"
      v-model="currentTabIdx"
      show-arrows
      background-color="transparent"
      class="mb-6 elevation-0"
    >
      <v-tab
        v-for="role in roles"
        :key="`v-tab-${role[0]}`"
      >
        {{ role[1] }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentTabIdx"
      style="background: transparent"
      touchless
    >
      <v-tab-item
        v-for="role in roles"
        :key="`v-tab-item-${role[0]}`"
      >
        <v-row class="pb-5">
          <v-col
            v-for="user of usersByRole[role[0]]"
            :key="`user${user.id}`"
            md="3"
            sm="3"
            cols="4"
            class="my-2"
          >
            <div class="d-flex flex-column align-center mb-4">
              <v-hover v-slot="{ hover }">
                <v-avatar
                  size="60"
                  class="cursor-pointer"
                  :class="{ 'elevation-4' : hover }"
                  color="secondary"
                  @click="edit(user)"
                >
                  <v-img
                    v-if="user.attributes.profileImage"
                    :src="user.attributes.profileImage"
                  />
                  <span
                    v-else
                    class="text-xs font-weight-bold"
                  >
                    {{ user.attributes.name.substring(0, 3) }}
                  </span>
                </v-avatar>
              </v-hover>

              <div class="mt-2">
                {{ user.attributes.name }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <!-- NOTE: 編集フォーム start -->
    <v-dialog
      v-if="editTargetUser.id"
      v-model="isEditing"
      width="500"
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-dialog
            v-model="deleteDialog"
            width="400"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                :ripple="false"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                削除の確認
              </v-card-title>

              <v-card-text>
                削除を実行してもよろしいですか
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="isSubmitting"
                  :disabled="isSubmitting"
                  :ripple="false"
                  color="error"
                  @click="deleteUser"
                >
                  同意の上で削除
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :ripple="false"
                  @click="deleteDialog = false"
                >
                  キャンセル
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>

        <v-card-text>
          <div class="w-hull d-flex align-center justify-center">
            <profile-image-input
              :value="editTargetUser.profileImage"
              :change="setEditImage"
              :display-image="editDisplayImage"
              class="my-4"
            />
          </div>

          <v-row class="mt-3">
            <v-col
              sm="12"
              md="6"
            >
              <v-text-field
                v-model="editTargetUser.name"
                label="名前"
              />
            </v-col>

            <v-col
              sm="12"
              md="6"
            >
              <v-text-field
                :value="{ cast: 'キャスト', waiter: 'ボーイ', alliance: 'アイライアンス' }[currentRole]"
                disabled
                label="ロール"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="isSubmitting"
            :disabled="Object.values({ ...isValidEditUser }).includes(false) || isSubmitting"
            :ripple="false"
            @click="updateUser"
          >
            更新
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :ripple="false"
            @click="cancelEdit"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- NOTE: 編集フォーム end -->
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import { groupBy, findIndex } from 'lodash'
import { mdiPlus, mdiTrashCanOutline } from '@mdi/js'
import UserApi from '@/api/waiter/User'
import ProfileImageInput from '@/views/components/util/ProfileImageInput.vue'

export default {
  components: {
    ProfileImageInput,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const currentTabIdx = ref(0)
    const isLoading = ref(false)
    const isCreating = ref(false)
    const isEditing = ref(false)
    const isSubmitting = ref(false)
    const users = ref([])
    const deleteDialog = ref(false)
    const addTargetUser = ref({
      name: '',
      role: '',
      tempImage: '',
    })
    const editTargetUser = ref({
      name: '',
      role: '',
      tempImage: '',
      profileImage: '',
    })
    const newUserProfileImageUploader = ref()
    const editUserProfileImageUploader = ref()
    const roles = [
      ['cast', 'キャスト'],
      ['waiter', 'ボーイ'],
      ['alliance', 'アライアンス'],
    ]
    const currentRole = computed(() => roles[currentTabIdx.value][0])

    const usersByRole = computed(() => {
      return groupBy(users.value, 'attributes.role')
    })

    const buildUserParams = ({
      id = null,
      name = null,
      role = null,
      profileImage = null,
      tempImage = null,
    }) => {
      return {
        id,
        name,
        role,
        profileImage,
        tempImage,
      }
    }

    const isValidAddUser = computed(() => {
      const { name, role } = addTargetUser.value

      return {
        name: !!name,
        role: !!role,
      }
    })

    const isValidEditUser = computed(() => {
      const { name, role } = editTargetUser.value

      return {
        name: !!name,
        role: !!role,
      }
    })

    const editDisplayImage = computed(() => {
      const { tempImage, profileImage } = editTargetUser.value
      const isString = typeof (profileImage) === 'string'
      const uploadedImage = isString ? profileImage : ''

      return tempImage || uploadedImage
    })

    const setImage = file => {
      addTargetUser.value.profileImage = file

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => addTargetUser.value.tempImage = reader.result
    }

    const setEditImage = file => {
      if (file) {
        editTargetUser.value.profileImage = file
        const reader = new FileReader()
        reader.readAsDataURL(file)
        editTargetUser.value.imageDelete = false
        reader.onload = () => editTargetUser.value.tempImage = reader.result
      } else {
        editTargetUser.value.profileImage = ''
        editTargetUser.value.tempImage = ''
      }
    }

    const edit = user => {
      isEditing.value = true
      editTargetUser.value = buildUserParams({
        id: user.id,
        name: user.attributes.name,
        role: user.attributes.role,
        profileImage: '',
        tempImage: user.attributes.profileImage,
      })
    }

    const cancelEdit = user => {
      isEditing.value = false

      editTargetUser.value = {
        name: '',
        role: '',
        tempImage: '',
        profileImage: '',
      }
    }

    const cancelCreate = () => {
      isCreating.value = false

      addTargetUser.value = {
        name: '',
        role: '',
        tempImage: '',
      }
    }

    const create = async () => {
      isSubmitting.value = true

      const res = await UserApi.createUser(addTargetUser)

      if (res.data) {
        users.value = [...res.data.users.data]
        isCreating.value = false
        vm.$toast.success('ユーザーを新規作成しました')
      }

      cancelCreate()
      isSubmitting.value = false
    }

    const updateUser = async () => {
      isSubmitting.value = true

      const res = await UserApi.updateUser({
        ...editTargetUser.value,
      })

      if (res.data) {
        const updatedUser = res.data.users.data
        const userIdx = findIndex(users.value, user => Number(user.id) === Number(updatedUser.id))
        users.value.splice(userIdx, 1, updatedUser)
        isEditing.value = false
        vm.$toast.success('ユーザーを更新しました')
      }

      cancelEdit()
      isSubmitting.value = false
    }

    const deleteUser = async () => {
      isSubmitting.value = true

      const res = await UserApi.deleteUser({ id: editTargetUser.value.id })

      if (res?.status === 200) {
        const userIdx = findIndex(users.value, user => Number(user.id) === Number(editTargetUser.value.id))
        users.value.splice(userIdx, 1)
        isEditing.value = false
        deleteDialog.value = false
        vm.$toast.success('ユーザーを削除しました')
      }
      isSubmitting.value = false
    }

    const getUsers = async () => {
      isLoading.value = true

      const res = await UserApi.getUsers()

      if (res?.data) {
        users.value = [...res.data.users.data]
      }

      isLoading.value = false
    }

    getUsers()

    return {
      // data
      roles,
      currentTabIdx,
      isLoading,
      isCreating,
      isEditing,
      isSubmitting,
      users,
      addTargetUser,
      editTargetUser,
      deleteDialog,
      newUserProfileImageUploader,
      editUserProfileImageUploader,

      // computed
      usersByRole,
      isValidAddUser,
      isValidEditUser,
      editDisplayImage,
      currentRole,

      // methods
      create,
      cancelCreate,
      edit,
      setImage,
      setEditImage,
      cancelEdit,
      updateUser,
      deleteUser,

      icons: {
        mdiPlus,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>
