<template>
  <div>
    <image-uploader
      v-show="false"
      ref="profileImageInput"
      :preview="false"
      output-format="blob"
      @input="change"
    >
    </image-uploader>

    <v-avatar
      :size="size"
      type="button"
      style="border: solid 4px rgba(255,255,255,.5);"
      @click="profileImageInput.$el.querySelector('input').click()"
    >
      <v-img
        v-if="displayImage"
        :src="displayImage"
        :max-width="size"
        :max-height="size"
        :min-width="size"
        :min-height="size"
      />

      <v-icon
        v-else
        :size="size/3"
      >
        {{ icons.mdiCamera }}
      </v-icon>
    </v-avatar>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCamera } from '@mdi/js'
import ImageUploader from 'vue-image-upload-resize'

export default {
  components: {
    ImageUploader,
  },
  props: {
    value: {
      type: [String, File, Blob],
      default: '',
    },
    size: {
      type: Number,
      default: 240,
    },
    change: {
      type: Function,
      default: () => {},
    },
    displayImage: {
      type: String,
      default: '',
    },
  },
  setup() {
    const profileImageInput = ref()

    return {
      profileImageInput,

      icons: {
        mdiCamera,
      },
    }
  },
}
</script>
